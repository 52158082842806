// Override Bootstrap code variables

$pre-color: $gray-600;

// Code variables
$code-font-family: Roboto Mono, monospace;

// Variables for <pre> element
$pre-bg: $gray-100;
$pre-line-height: 1.5rem;
$pre-margin-x: 0;
$pre-margin-y: 0.5rem;
$pre-padding-x: 1rem;
$pre-padding-y: 1.5rem;
