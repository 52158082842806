// Add soft color variations

$red-soft:       mix($gray-100, $red, 90%);
$orange-soft:    mix($gray-100, $orange, 90%);
$yellow-soft:    mix($gray-100, $yellow, 90%);
$green-soft:     mix($gray-100, $green, 90%);
$teal-soft:      mix($gray-100, $teal, 90%);
$cyan-soft:      mix($gray-100, $cyan, 90%);
$blue-soft:      mix($gray-100, $blue, 90%);
$indigo-soft:    mix($gray-100, $indigo, 90%);
$purple-soft:    mix($gray-100, $purple, 90%);
$pink-soft:      mix($gray-100, $pink, 90%);

$primary-soft:   mix($gray-100, $primary, 90%);
$secondary-soft: mix($gray-100, $secondary, 90%);
$success-soft:   mix($gray-100, $success, 90%);
$info-soft:      mix($gray-100, $info, 90%);
$warning-soft:   mix($gray-100, $warning, 90%);
$danger-soft:    mix($gray-100, $danger, 90%);

// Add expanded colors to the color map

$theme-colors: (
    'black': $black,
    'white': $white,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red-soft': $red-soft,
    'orange-soft': $orange-soft,
    'yellow-soft': $yellow-soft,
    'green-soft': $green-soft,
    'teal-soft': $teal-soft,
    'cyan-soft': $cyan-soft,
    'blue-soft': $blue-soft,
    'indigo-soft': $indigo-soft,
    'purple-soft': $purple-soft,
    'pink-soft': $pink-soft,
    'primary-soft': $primary-soft,
    'secondary-soft': $secondary-soft,
    'success-soft': $success-soft,
    'info-soft': $info-soft,
    'warning-soft': $warning-soft,
    'danger-soft': $danger-soft,
);