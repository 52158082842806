#layoutDefault {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    #layoutDefault_content {
        min-width: 0;
        flex-grow: 1;
    }
    #layoutDefault_footer {
        min-width: 0;
    }
}
