// * * * * * * * * * * * * *
// * * Import variables  * *
// * * * * * * * * * * * * *

@import './variables.scss';

// * * * * * * * * * * * * *
// * * Import Bootstrap  * *
// * * * * * * * * * * * * *

@import './bootstrap/bootstrap.scss';

// * * * * * * * * * * * * * * *
// * * Import global styling * *
// * * * * * * * * * * * * * * *

@import './global.scss';

// * * * * * * * * * * * *
// * * Import layouts  * *
// * * * * * * * * * * * *

@import './layout/default.scss';

// * * * * * * * * * * * * *
// * * Import utilities  * *
// * * * * * * * * * * * * *

@import './utilities/animation.scss';
@import './utilities/background.scss';
@import './utilities/border.scss';
@import './utilities/dropdown.scss';
@import './utilities/general.scss';
@import './utilities/images.scss';
@import './utilities/lift.scss';
@import './utilities/shadow.scss';
@import './utilities/text.scss';
@import './utilities/zindex.scss';

// * * * * * * * * * * * * * * * *
// * * Import component styles * *
// * * * * * * * * * * * * * * * *

// -- Alerts
@import './components/alerts/icon.scss';
@import './components/alerts/solid.scss';

// -- Avatars
@import './components/avatars.scss';

// -- Buttons
@import './components/buttons/global.scss';
@import './components/buttons/icon.scss';
@import './components/buttons/sizing.scss';
@import './components/buttons/social.scss';
@import './components/buttons/transparent.scss';

// -- Cards
@import './components/cards/global.scss';
@import './components/cards/advanced.scss';
@import './components/cards/collapsable.scss';
@import './components/cards/icon.scss';
@import './components/cards/scrollable.scss';
@import './components/cards/progress.scss';
@import './components/cards/link.scss';
@import './components/cards/portfolio.scss';
@import './components/cards/flags.scss';

// -- Forms
@import './components/forms/checkboxes.scss';
@import './components/forms/form-control.scss';

// -- Navigation
@import './components/navigation/navbar.scss';
@import './components/navigation/navbar-marketing.scss';
@import './components/navigation/topnav.scss';
@import './components/navigation/nav-sticky.scss';

// -- Dropdowns
@import './components/dropdowns/global.scss';
@import './components/dropdowns/user.scss';
@import './components/dropdowns/notifications.scss';
@import './components/dropdowns/mega.scss';

// -- Icons
@import './components/icons.scss';

// -- Video
@import './components/video.scss';

// -- Media
@import './components/media.scss';

// -- Accordion
@import './components/accordion.scss';

// -- Badges
@import './components/badges.scss';

// -- Pagination
@import './components/pagination.scss';

// -- List Group
@import './components/list-group.scss';

// * * * * * * * * * * * * * * *
// * * Import section styles * *
// * * * * * * * * * * * * * * *

@import './sections/borders.scss';
@import './sections/header.scss';
@import './sections/footer.scss';
@import './sections/device.scss';
@import './sections/brands.scss';
@import './sections/testimonials.scss';
@import './sections/pricing.scss';
@import './sections/blog.scss';
@import './sections/company.scss';

// * * * * * * * * * * * * * * *
// * * Import custom styles  * *
// * * * * * * * * * * * * * * *
@import './project-global.scss';