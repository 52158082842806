.z-1 {
    z-index: 1 !important;
    position: relative !important;
}

.z-2 {
    z-index: 2 !important;
    position: relative !important;
}

.o-visible {
    overflow: visible  !important;
}

.o-hidden {
    overflow: hidden  !important;
}