.post-preview {
    h5.card-title {
        font-size: 1.1rem;
        margin-bottom: 0.5rem;
    }
    .post-preview-meta {
        display: flex;
        align-items: center;
        margin-right: 0.5rem;
        .post-preview-meta-img {
            height: 2.25rem;
            width: 2.25rem;
            border-radius: 100%;
            margin-right: 0.5rem;
            flex-shrink: 0;
        }
        .post-preview-meta-details {
            .post-preview-meta-details-name {
                font-size: 0.9rem;
                line-height: 1;
                margin-bottom: 0.25rem;
            }
            .post-preview-meta-details-date {
                font-size: 0.75rem;
                line-height: 1;
            }
        }
    }
}

.post-preview-featured {
    .post-preview-featured-img {
        height: 20rem;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    h5.card-title {
        font-size: 1.75rem;
    }
    p.card-text {
        font-size: 1.2rem;
        font-weight: 300;
    }
    @include media-breakpoint-up(lg) {
        .post-preview-featured-img {
            height: 100%;
            width: 100%;
        }
    }
}

.post-archive-tag {
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid $border-color;
    margin-bottom: 3rem;
    padding-bottom: 0.5rem;
}

.post-archive-item {
    color: inherit !important;
    text-decoration: none !important;
}

.post-archive-meta {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
    .post-archive-meta-img {
        height: 2.25rem;
        width: 2.25rem;
        border-radius: 100%;
        margin-right: 0.5rem;
        flex-shrink: 0;
    }
    .post-archive-meta-details {
        .post-archive-meta-details-name {
            font-size: 0.9rem;
            line-height: 1;
            margin-bottom: 0.25rem;
        }
        .post-archive-meta-details-date {
            font-size: 0.75rem;
            line-height: 1;
        }
    }
}

.single-post {
    .single-post-meta {
        display: flex;
        align-items: center;
        margin-right: 0.5rem;
        .single-post-meta-img {
            height: 2.25rem;
            width: 2.25rem;
            border-radius: 100%;
            margin-right: 0.5rem;
            flex-shrink: 0;
        }
        .single-post-meta-details {
            .single-post-meta-details-name {
                font-size: 0.9rem;
                line-height: 1;
                margin-bottom: 0.25rem;
            }
            .single-post-meta-details-date {
                font-size: 0.75rem;
                line-height: 1;
            }
        }
    }
    .single-post-meta-links {
        font-size: 1.25rem;
        a {
            color: $gray-400;
            margin-left: 0.5rem;
            &:hover {
                color: $gray-600;
            }
        }
    }
    .single-post-text {
        font-size: 1.1rem;
        p {
            margin-bottom: 1.5rem;
        }
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-top: 2.5rem;
            margin-bottom: 1.5rem;
        }
    }
}