// * * * * * * * * * * * * * * * *
// * * Import color variables  * *
// * * * * * * * * * * * * * * * *

@import './variables/colors.scss';

// * * * * * * * * * * * * * * * * * * *
// * * Import custom color variables * *
// * * * * * * * * * * * * * * * * * * *

// Add color overrides in this file, or replace this file with a file exported from the customizer
@import './variables-colors-overrides.scss';

// * * * * * * * * * * * * * * * *
// * * Import global variables * *
// * * * * * * * * * * * * * * * *

@import './variables/colors-expanded.scss';
@import './variables/spacing.scss';
@import './variables/body.scss';
@import './variables/grid.scss';
@import './variables/components.scss';
@import './variables/typography.scss';

// * * * * * * * * * * * * * * * * * *
// * * Import component variables  * *
// * * * * * * * * * * * * * * * * * *

@import './variables/alerts.scss';
@import './variables/page-header.scss';
@import './variables/navbar.scss';
@import './variables/error.scss';
@import './variables/buttons-forms.scss';
@import './variables/navigation.scss';
@import './variables/cards.scss';
@import './variables/dropdowns.scss';
@import './variables/code.scss';
@import './variables/tables.scss';
@import './variables/badges.scss';

// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
// * * Import custom styles - add your own custom variables here!  * *
// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

@import './variables-sb-ui-kit-pro-overrides.scss';
