.topnav {
    .navbar-brand {
        font-size: 1rem;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        img {
            height: 1rem;
        }
    }
}
