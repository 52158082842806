// This file is intentionally blank
// Use this file to override SCSS within the project, or to add new SCSS for creating your own CSS components

img.logo {
	width: 155px;
	height: 50px;
}

.fab-32 {
	font-size: 32px;
}