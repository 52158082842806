.card-portfolio {
    position: relative;
    // Fixes the border radius transform issue on Safari
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    mask-image: radial-gradient(white, black);

    .card-body {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        height: 3.5rem;
        width: 100%;
        background-color: $white;
        text-align: center;

        .card-title {
            font-size: 0.75rem;
            margin: 0;
            text-transform: uppercase;
            font-weight: 800;
            letter-spacing: 0.1em;
            color: $gray-800;
        }
    }

    @include media-breakpoint-up(lg) {
        .card-body {
            height: 100%;
            bottom: -100%;
            transition: bottom 0.15s ease-in-out;
            .card-title {
                color: $white;
            }
        }

        .card-img-top {
            transition: transform 0.45s ease-in-out;
        }

        &:hover {
            .card-body {
                bottom: 0;
                background-color: fade-out($primary, 0.1);
            }

            .card-img-top {
                transform: scale(1.2);
            }
        }
    }
}

.card-columns-portfolio {
    .card {
        box-shadow: none;
    }

    @include media-breakpoint-only(sm) {
        column-count: 1;
    }

    @include media-breakpoint-only(md) {
        column-count: 2;
    }

    @include media-breakpoint-only(lg) {
        column-count: 2;
    }

    @include media-breakpoint-only(xl) {
        column-count: 3;
    }
}

.card-columns-case-study {
    .card {
        box-shadow: none;
    }

    @include media-breakpoint-only(sm) {
        column-count: 1;
    }

    @include media-breakpoint-only(md) {
        column-count: 2;
    }

    @include media-breakpoint-only(lg) {
        column-count: 2;
    }

    @include media-breakpoint-only(xl) {
        column-count: 2;
    }
}